import { FC } from "react"

import config from "../../config.json"
import { WrappedBase } from "../shared-components/Shared/WrappedBase"

import { EmbeddableMortgageComparison } from "../views/MortgageComparison"

const EmbeddableComparison: FC = () => {
  return (
    <WrappedBase
      metaTitle="Mortgage Comparison: Compare Mortgage Rates Online | Habito"
      metaDescription="Compare mortgage rates across the whole market – thousands of mortgages from over 90 lenders – to see the best mortgage rates today. Find your perfect mortgage now."
      canonicalUrl="https://www.habito.com/mortgage-comparison"
      noIndex={true}
      pageName="embeddable_comparison_tool"
      intercom={false}
      config={config}
      head={null}
    >
      <EmbeddableMortgageComparison />
    </WrappedBase>
  )
}

export default EmbeddableComparison
